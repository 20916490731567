<template>
  <b-card>
    <header-details
      @on:cancel-edit="$router.push({name: 'duplicate'})"
    />
    <b-row>
      <b-col v-if="getSelectedBlockPriceCategory">
        <div class="d-flex">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.park-model') }}:</span>
          <d-text-field :value="getSelectedBlockPriceCategory.club.name" class-name="bg-white" readonly size="sm"/>

          <span class="modal-title font-weight-bold mt-1 mr-2 ml-5">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
        <div class="d-flex mt-2">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.used-block-price-category') }}:</span>
          <d-text-field
            :value="getSelectedBlockPriceCategory.name"
            readonly
            size="sm"
            class-name="bg-white"
            class="cursor-disabled"
          />
        </div>
      </b-col>
      <b-col v-else>
        <div class="d-flex">
          <span class="modal-title font-weight-bold mr-3 mt-1">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-2">
      <b-col v-if="getSelectedBlockPriceCategory" cols="7">
        <fieldset>
          <legend class="d-flex justify-content-center font-weight-bold h6">
            {{
              getSelectedBlockPriceCategory.club.name
            }}
          </legend>
          <block-price-category-form
            :block-price-category="getSelectedBlockPriceCategory"
          />
          <div class="d-flex justify-content-center">
            <d-button
              :text="$t('admin.duplicate.set-data')"
              class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
              @on:button-click="onDuplicateAll"
            />
          </div>
        </fieldset>
      </b-col>
      <b-col cols="12">
        <div style="height: 40px" class="w-100 bg-light-red d-flex justify-content-center align-items-center">
          {{ $t('admin.duplicate.block-price-category-to-edit') }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        v-for="(item, index) of groupedSelectedBlockPriceCategoriesByClub"
        :key="'checked-' + index"
        cols="6"
        class="mt-3"
      >
        <fieldset>
          <legend style="width: auto" class="d-flex font-weight-bold h6">
            <span class="ml-2 mr-2">{{ item.club.name }}</span>
          </legend>
          <template
            v-for="(blockPriceCategory, index) of item.categories"
          >
            <block-price-category-form
              :key="`block-price-category-${index}`"
              :has-border="index < item.categories.length - 1"
              :block-price-category="blockPriceCategory"
            />
          </template>
        </fieldset>
      </b-col>

      <b-col cols="12" align="middle">
        <d-button
          :text="$t('admin.duplicate.validate-data')"
          class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
          @on:button-click="displayModal"
        />
      </b-col>
      <progress-bar-modal
        modalId="identifier-validate"
        :display="display"
        title="general.actions.notification"
        :is-busy="isBusy"
        :count="count"
        :text="$t('admin.duplicate.ask-for-confirmation')"
        :hide="hide"
        @on:cancel="hide = !hide"
        @on:confirm="onValidate"
      />
    </b-row>
  </b-card>
</template>

<script>
import HeaderDetails from "@views/duplicate/HeaderDetails";
import DuplicateShopCategoryForm from "@custom/duplicate/DuplicateShopCategoryForm";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {_put} from "@api/services/httpService";
import ProgressBarModal from "@custom/duplicate/ProgressBarModal";
import PermanentForm from "@views/duplicate/calendar/PermanentForm";
import {cloneData} from "@/utils/form";
import HolidaysForm from "@views/duplicate/calendar/HolidaysForm";
import BlockPriceCategoryForm from "@views/duplicate/block-price-category/BlockPriceCategoryForm";

export default {
  data: () => ({
    display: false,
    hide: false,
    count: {
      success: 0,
      failed: 0,
      totalCount: 0,
    },
    isBusy: false
  }),
  computed: {
    getSelectedBlockPriceCategory() {
      return this.$store.getters['blockPriceCategory/getSelectedBlockPriceCategoryModel'];
    },
    getCheckedBlockPriceCategories() {
      return this.$store.getters['blockPriceCategory/getCheckedBlockPriceCategories'];
    },
    impactedParks() {
      let label = '';
      for (let i = 0; i < this.groupedSelectedBlockPriceCategoriesByClub.length; i++) {
        label += i < this.groupedSelectedBlockPriceCategoriesByClub.length - 1 ? this.groupedSelectedBlockPriceCategoriesByClub[i].club.name + ',' : this.groupedSelectedBlockPriceCategoriesByClub[i].club.name;
      }
      return label;
    },
    groupedSelectedBlockPriceCategoriesByClub() {
      let groupList = [];

      for (const item of this.getCheckedBlockPriceCategories) {
        if (groupList.length > 0) {
          const checkIfAdded = groupList.find(el => el.club.id === item.club.id);

          if (isNotUndefinedAndNotNull(checkIfAdded)) {
            checkIfAdded.categories.push(item);
          } else {
            groupList.push({club: item.club, categories: [item]});
          }
        } else {
          groupList.push({club: item.club, categories: [item]});
        }
      }

      return groupList.sort((b, a) => a.categories.length - b.categories.length);
    }
  },
  components: {BlockPriceCategoryForm, HolidaysForm, PermanentForm, ProgressBarModal, HeaderDetails},
  methods: {
    displayModal() {
      this.count.success = 0;
      this.count.failed = 0;
      this.count.totalCount = this.getCheckedBlockPriceCategories.length;
      this.display = !this.display;
    },
    async onValidate() {
      if (this.isBusy === false) {
        this.isBusy = true;
        this.count = {success: 0, failed: 0, totalCount: this.getCheckedBlockPriceCategories.length}

        for (let g = 0; g < this.groupedSelectedBlockPriceCategoriesByClub.length; g++) {
          const group = this.groupedSelectedBlockPriceCategoriesByClub[g];

          for (let i = 0; i < group.categories.length; i++) {
            const blockPriceCategory = group.categories[i];
            let photo  = null;
            if (blockPriceCategory.photo) {
              if (isNotUndefinedAndNotNull(blockPriceCategory.photo['@id'])) {
                photo = blockPriceCategory.photo['@id']
              } else {
                photo = blockPriceCategory.photo;
              }
            }
            await _put(blockPriceCategory['@id'], {
              name: blockPriceCategory.name,
              tags: blockPriceCategory.tags,
              position: blockPriceCategory.position,
              color: blockPriceCategory.color,
              photo: photo,
              description: blockPriceCategory.description
            })
              .then((response) => {
                this.count.success++;
              })
              .catch((err) => {
                this.count.failed++;
              })
              .finally(() => {
                if (this.count.success + this.count.failed === this.count.totalCount) {
                  this.isBusy = false;

                  if (g === this.groupedSelectedBlockPriceCategoriesByClub.length - 1 && this.count.failed === 0) {
                    this.$nextTick(() => {
                      setTimeout(() => {
                        this.$router.replace({name: 'duplicate'});
                        this.$vs.notify({
                          time: 5000,
                          title: this.$t('general.actions.notification'),
                          text: this.$t('general.actions.success-update'),
                          color: 'success',
                          position: 'top-right'
                        });
                      }, 2000)
                    })
                  }
                }
              })
            ;
          }
        }
      }
    },
    onDuplicateAll() {
      const calendar = cloneData(this.getSelectedBlockPriceCategory);
      delete calendar.club;
      delete calendar['@id'];

      this.$store.commit('blockPriceCategory/updateBlockPriceCategoryCheckedList', calendar);
    }
  },

  mounted() {
    console.log(this.groupedSelectedBlockPriceCategoriesByClub, 'groupedSelectedBlockPriceCategoriesByClub');
  }
}
</script>
<style scoped lang="scss">

.bg-light-red {
  background-color: #FF0101;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 294px);
  left: 100px;
}

@import "@lazy/_b-card.scss";
</style>
