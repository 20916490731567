<template>
  <div>
    <b-row>
      <b-col cols="12" align="middle">
        <div class="product-box mt-2">
          <div class="product-img">
            <img v-if="blockPriceCategory.photo" height="140" width="180" :src="$filePath(blockPriceCategory.photo.contentUrl)">

            <img
              v-else
              class="pointer"
              height="140" width="180"
              src="@/assets/upload-img.svg"
              @click="upload"
              alt
            />
            <div class="product-hover">
              <ul
                v-if="blockPriceCategory.photo === null"
              >
                <li
                  class="pointer"
                  @click="upload"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-upload"></i>
                  </button>
                </li>
              </ul>
              <ul v-else>
                <li
                  class="pointer"
                  @click="resetPhoto"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-trash"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <label class="d-flex justify-content-center"> Image
          <i @click="onCopy('photo')" class="pointer mt-1 ml-2 fa-15x" :class="icon"></i>
        </label>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <label> {{ $t('admin.duplicate.calendar.name') }}</label>
            <i @click.stop="onCopy('name')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
              <d-text-field
                v-model="blockPriceCategory.name"
                error-text="validation.text-input-three-characters"
                class="btn-full-width"
                class-name="bg-white"
                size="sm"
              />
            </span>
          </b-col>
          <b-col cols="4">
            <label> {{ $t('admin.duplicate.block-price-category.position') }}</label>
            <i @click="onCopy('position')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
              <d-text-field
                v-model.number="blockPriceCategory.position"
                error-text="validation.text-input-three-characters"
                class="btn-full-width"
                type="number"
                class-name="bg-white"
                size="sm"
              />
            </span>
          </b-col>
          <b-col cols="4">
            <label> {{ $t('admin.duplicate.block-price-category.color') }}</label>
            <i @click="onCopy('color')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
              <b-form-input
                v-model="blockPriceCategory.color"
                class="form-control"
                size="sm"
                type="color"
              />
            </span>
          </b-col>
          <b-col class="mt-1" cols="12">
            <label> {{ $t('admin.duplicate.block-price-category.tags') }}</label>
            <i @click="onCopy('tags')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
              <b-form-input
                v-model="tag"
                class="form-control"
                size="sm"
                @keyup.enter.stop="onAddTag"
              />
                <d-button
                  text="admin.duplicate.block-price-category.add"
                  class="ml-2 d-btn-sm d-btn btn d-btn-primary-new font-text-title"
                  @on:button-click.stop="onAddTag"
                />
            </span>
          </b-col>
          <b-col cols="12" class="mt-1">
            <div class="mt-1 d-flex flex-row flex-wrap">
              <div
                v-for="(el, i) of blockPriceCategory.tags"
              >
                <span
                  :key="'tag-customer' + i"
                  class="custom-tag tag-light-blue mt-1 mr-1"
                >
                  {{ el }}
                <button
                  type="button" class="close-icon close"
                  data-dismiss="alert"
                >
                  <span
                    @click="onRemoveTag(el)"
                    aria-hidden="true"
                  >
                    &times;
                   </span>
                  </button>
                </span>
              </div>
            </div>
          </b-col>
          <b-col class="mt-1" cols="12">
            <label> {{ $t('admin.duplicate.block-price-category.description') }}</label>
            <i @click="onCopy('description')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
              <b-textarea
                v-model="blockPriceCategory.description"
                class="form-control"
                size="sm"
              />
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="hasBorder" class="border-bottom-black mt-3 mb-4"/>

  </div>
</template>
<script>

import {fromIdToIriReference} from "@/utils/form";
import {URI_BLOCK_PRICE} from "@api/services/timetable/blocks/price/price.api";
import {_delete} from "@api/services/httpService";
import {BLOCK_PRICE_CATEGORY_PHOTO_URL} from "@api/services/timetable/blocks/price/category/photo/category-photo.api";
import {BLOCK_PRICE_CATEGORY_URI} from "@api/services/timetable/blocks/price/category/category.api";

export default {
  data: () => ({
    tag: '',
  }),
  props: {
    blockPriceCategory: {
      type: Object,
      default: this
    },
    icon: {
      type: String,
      default: 'icofont icofont-download-alt text-danger'
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onAddTag() {
      if (this.tag !== '') {
        this.blockPriceCategory.tags.push(this.tag);
        this.tag = '';
      }
    },
    onRemoveTag(tag) {
      this.blockPriceCategory.tags.splice(this.blockPriceCategory.tags.indexOf(tag), 1);
    },
    upload() {
      this.$upload(this.uploaded, {
        entry: 'category',
        url: fromIdToIriReference(BLOCK_PRICE_CATEGORY_URI, this.blockPriceCategory.id),
        target: 'postBlockPriceCategoryPhoto'
      });
    },
    resetPhoto() {
      if (this.blockPriceCategory.id && this.blockPriceCategory.photo) {
        _delete(this.blockPriceCategory.photo['@id']);
      }

      this.blockPriceCategory.photo = null;
    },
    uploaded(resp) {
      this.blockPriceCategory.photo = resp;
    },
    onCopy(key) {
      const payload = {};
      payload[key] = this.blockPriceCategory[key];

      this.$store.commit('blockPriceCategory/updateBlockPriceCategoryCheckedList', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
.close-icon {
  font-size: 15px;
  color: #dc3545;
  margin-left: 3px;
}

.tag-light-blue {
  font: normal normal 13px Avenir;
  letter-spacing: 0.44px;
  color: #3b3f45;
  opacity: 1;
  border: 1px solid #ced4da;
  background-color: #daddde
}

.custom-tag {
  display: inline-block;
  padding: 0.25em 0.4em;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
</style>
